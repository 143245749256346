<template>
  <div class="homeBanner">
    <div class="swiper-container" ref="mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide"  
          :key="'homeBanner_'+index" 
          v-for="(item,index) in datas">
          <img :src="item.url" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      btnStatus: false
    }
  },
  props: {
    "datas":{
      default: []
    }
  },
  computed: {
    ...Vuex.mapGetters([
      "screen", 
    ]),
    swiper() {
      return this.$refs.mySwiper
    }
  },
  
  mounted() {
    var swiper = new Swiper(this.swiper, {
      loop: true,
      speed: 800,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 20000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChange: function () {
          console.log(this,this.activeIndex);

        },
      }
      
    });
    
  },
  methods: {
    videoReady(index){
      this.$set(this.datas[index],"videoReady",true);
    },
    toHref(link){
      if(!link)return;
      location.href = link;
    },
    bannerUrl(url,MUrl){
      if(this.screen){
        return MUrl;
      }else{
        return url;
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.homeBanner{
  font-size: $Fts3;
  position: relative;
  height: 856px;
  overflow: hidden;
}
.swiper-slide{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.15);  
    z-index: 2;
  }
  & .btn{
    position: absolute;  
    left: 5vw;
    top: 160px;
    z-index: 3;
    font-size: $Ftb8;
    color: $Cf;
    & img{
      width: 280px !important;  
      margin-bottom: .64rem;
    }
    & div{
      text-transform: uppercase;
      font-family: "enB";
      width: 30vw;     
      // min-width: 3rem;
    }
    & a{
      display: inline-block;
      width: 2.98rem;
      background-color: $CLGy1;
      height: .56rem;
      line-height: .56rem;
      font-size: $Fts4;
      text-align: center;
      color: $Cf;
      margin-top: .8rem;
      &.enter{        
        animation: btnEnterKey 0.2s ease-in-out;
        -webkit-animation: btnEnterKey 0.2s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.1s;
        -webkit-animation-delay: 0.1s;
      }
      &.leave{
        animation: btnLeaveKey 0.2s ease-in-out;
        -webkit-animation: btnLeaveKey 0.2s ease-in-out;
        animation-fill-mode: forwards;

      }
    }
  }

}
.homeBanner .swiper-slide > img,video{
  min-height: 856px;
  width: auto;
  min-width: 100vw;
  max-width: calc( 856px * 1.8 );
}
.swiper-pagination{
  bottom: .2rem;
  margin 0 auto;
  width: 100%;
  & span{
    outline: none;  
  }
}

@media screen and (max-width: 980px){
  .homeBanner{
    min-height: 5.2rem;
    height: auto;

  } 
  .swiper-slide{
    & .btn{
      font-size: $Ftm4;
      top: 36%;
      & div{
        width: 70vw; 
      }
      & a{
        margin-top: .8rem;  
        width: 2.2rem;
        height: .42rem;
        line-height: .42rem;
      }
    }
  }
  .homeBanner .swiper-slide > img,video{
    // width: 100%;
    height: auto;
    min-height: auto;
    max-width: calc( 856px * 1.4 );
  }
}

@keyframes btnEnterKey {
  from {
    background-color: $CLGy1;
  }
  to {
    background-color: #DB5900;

  }
}
@keyframes btnLeaveKey {
  from {
    background-color: #DB5900;
  }
  to {
    background-color: $CLGy1;    
  }
}
</style>

<style lang="stylus">

.homeBanner .swiper-pagination-bullet{
  display: inline-block;
  width: .8rem;
  border-radius(0);
  height: .07rem;
  margin: 0 .06rem;
  background-color: $Cf;
  opacity: 1;
  outline: none;
}
.homeBanner .swiper-pagination-bullet-active{
  background-color: $CLGy1;  
}
</style>